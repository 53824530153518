import React from "react";

function NotAvailable(){
    return(
        <>
            <h1 style={{display:"flex",justifyContent:"center"}}>This page is Not Available yet</h1>
        </>
    )
}

export default NotAvailable;
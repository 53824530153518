import React from "react";
import logo from "./images/uplogo.png";
import { Link } from 'react-router-dom';

function Navhead() {
  return (
    <div className="navbar">
      <div className="logo">
        <Link to="/">
          <img id="logo" src={logo} alt="Home Logo" />
        </Link>
      </div>
      <div className="menu">
      <Link to="/compaint">File a complaint</Link>
        <div className="dropdown">
          <button className="dropbtn">Services</button>
          <div className="dropdown-content">
            <Link to="birth_registration">Birth Registration</Link>
            <Link to="death_registration">Death Registration</Link>
            <Link to="property">Property Tax</Link>
            <Link to="water_Bill">Water Bill</Link>
            
          </div>
        </div>
        <Link to="dept">Department</Link>
        <Link to="/tender">Tender</Link>
        <Link to="/Imggallery">Gallery</Link>
        <Link to="/Itemmap">Map</Link>
        <div className="dropdown">
          <button className="dropbtn">E_Upload</button>
          <div className="dropdown-content">
            <Link to="/E_magazine">E-Magazine</Link>
            <Link to="/E_Audit">E-Audit</Link>
            <Link to="/E_Balance_Sheet">E-Balance Sheet</Link>
            <Link to="/Budget">Budget</Link>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">About City</button>
          <div className="dropdown-content">
            <Link to="#">History</Link>
            <Link to="#">Ward</Link>
          </div>
        </div>
        <Link to="/Contact">Contact</Link>
      </div>
    </div>
  );
}

export default Navhead;

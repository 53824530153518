import React,{useEffect,useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Link } from "react-router-dom";



function Celebrationsec(){
  const sliderSettings = {
    vertical: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
      autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };
  const db = getDatabase();

  const [data, setData] = useState(null);
  const [datatext, setDatatext] = useState(null);

  useEffect(() => {
    const dataRef = ref(db, '/celebsec'); // Replace 'yourDatabasePath' with your database path
    const datatextref=ref(db,'/Celebsectext');
    // Listen for changes to the data
    
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(Object.values(fetchedData || {}));
    });
    onValue(datatextref, (snapshot) => {
      const fetchedData = snapshot.val();
      setDatatext(Object.values(fetchedData || {}));
    });
  }, []);
 
  

    return(
      
        <div className="CelebSec">
            
            <div className="celebimg">
            <Slider {...sliderSettings}>
        {data ? (
          data.map((image, index) => (
            <div key={index}>
             <Link to={`/Imggallery`} title="View Images"> <img className="imgset" src={image} alt={`Image ${index + 1}`} /></Link>
            </div>
          ))
        ) : (
          <p>Loading....</p>
        )}
      </Slider>
            
            </div>
            <div className="vertical-auto-scroll-text">
      <div className="scrolling-content">
      {datatext ? (
            datatext[0].split('\\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))
          ): (
            // Static content as a fallback
            <p>
              loding.....<br />
              {/* ... Rest of the static content */}
            </p>
          )}
             {/* <p>
सुप्रभात आप सभी को! <br></br>

स्वतंत्रता दिवस के इस शुभ अवसर पर, मैं गर्व से खड़ा होकर भारतीय जनता को मेरा सादर प्रणाम भेजता हूँ। आज हम सभी एक महत्वपूर्ण दिन के रूप में मना रहे हैं, जिसे हम स्वतंत्रता दिवस के रूप में जानते हैं। इस दिन हमारा देश आजाद हुआ था, और हमें आजादी का गर्व महसूस होता है।<br/>

स्वतंत्रता दिवस हमें याद दिलाता है कि हमारे पूर्वजों ने कितनी महेनत और बलिदान के साथ आजादी पाई थी। महात्मा गांधी, जवाहरलाल नेहरू, सरदार पटेल, भगत सिंह, राजेन्द्र प्रसाद जैसे महान भारतीय नेताओं ने देश को स्वतंत्रता की ओर अग्रसर किया। उनका आदर्श हमारे लिए प्रेरणास्पद है और हमें याद दिलाता है कि हमें अपने देश के प्रति आदर्श और समर्पण रखना चाहिए।<br/>

आज हमारे देश में भारतीय स्वतंत्रता के 75 वर्ष पूरे हो रहे हैं, और हमें गर्व है कि हमारे देश के लोगों ने इतनी बड़ी उपलब्धि प्राप्त की है। लेकिन हमें यह नहीं भूलना चाहिए कि हमें अपने देश के विकास और समृद्धि के लिए काम करना है।<br/>

मेरा संदेश है कि हमें एकजुट होकर देश के विकास के लिए काम करना है। हमें सामाजिक समरसता, गरीबी की हानि, शिक्षा के प्रति समर्पितता, और सभी लोगों के अधिकारों का सम्मान करना है। हमें अपने देश को महान बनाने के लिए सक्रिय भूमिका निभानी है, क्योंकि यही वास्तविक स्वतंत्रता है।<br/>

इस खास दिन पर, हमें अपने देश के प्रति अपना समर्पण और प्रेम पुनः दिखाने का मौका मिलता है। आओ, हम सभी मिलकर भारतीय स्वतंत्रता के इस महत्वपूर्ण पल को याद करें और हमारे देश को और भी महान बनाने के लिए प्रतिबद्ध रहें। <br/>

धन्यवाद, जय हिन्द!
        </p>  */}
      </div>
    </div>

        </div>

    )
}
export default Celebrationsec;
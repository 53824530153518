import React from "react";
import head from "./images/head.png";

function Header(){
    return(
        <div id="header">
      <img src={head} href="test.htm"/>
        </div>
    )
}
export default Header;
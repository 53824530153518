import React, { useEffect, useState,useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { getDatabase, ref, onValue } from 'firebase/database';



function Imggallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
    const db = getDatabase();

    const [data, setData] = useState(null);
  
    useEffect(() => {
      const dataRef = ref(db, '/Gallery'); // Replace with your database path
  
      // Listen for changes to the data
      onValue(dataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        setData(Object.values(fetchedData || {}));
      });
    }, [db]);

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    }; 
   


  return (
    <div>
      
    {data? (
    data.map((src, index) => (
      <img
        src={src}
        onClick={() => openImageViewer(index)}
        width="300"
        key={index}
        style={{ margin: "2px" }}
        alt=""
      />
    ))
  ):
  (
    <p>Loading...</p>
  )}

    {isViewerOpen && (
      <ImageViewer
        src={data}
        currentIndex={currentImage}
        onClose={closeImageViewer}
        disableScroll={false}
        backgroundStyle={{
          backgroundColor: "rgba(0,0,0,0.9)"
        }}
        closeOnClickOutside={true}
      />
    )}
  </div>
  );
}

export default Imggallery;

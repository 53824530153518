import React from "react";

function PageNotFound(){
    return(
        <>
            <h1 style={{display:"flex",justifyContent:"center"}}>404 Not Found</h1>
        </>
    )
}

export default PageNotFound;
import React from "react";
import { Link } from 'react-router-dom';

function Menuitem() {
  return (
    <div className="responsive-menu" style={{ background: "white" }}>
      <div className="Hammenu"><Link to="/compaint">File a complaint</Link></div>
      <div className="dropdown">
        <button className="dropbtn">Services</button>
        <div className="dropdown-content">
          <Link to="#">Birth Registration</Link>
          <Link to="#">Death Registration</Link>
          <Link to="#">Property Tax</Link>
          <Link to="#">Water Bill</Link>
          
        </div>
      </div>
      
      <div className="Hammenu"><Link to="#">Department</Link></div>
      <div className="Hammenu"><Link to="/tender">Tender</Link></div>
      <div className="Hammenu"><Link to="/Imggallery">Gallery</Link></div>
      <div className="Hammenu"><Link to="/Itemmap">Map</Link></div>
      <div>
      <div className="dropdown">
        <button className="dropbtn">E_Upload</button>
        <div className="dropdown-content">
        <Link to="/E_magazine">E-Magazine</Link>
            <Link to="/E_Audit">E-Audit</Link>
            <Link to="/E_Balance_Sheet">E-Balance Sheet</Link>
            <Link to="/Budget">Budget</Link>
        </div>
      </div>
      </div>
      <div>
      <div className="dropdown">
        <button className="dropbtn">About City</button>
        <div className="dropdown-content">
          <Link to="#">History</Link>
          <Link to="#">Ward</Link>
        </div>
      </div>
      </div>
      <div className="Hammenu">
      <Link to="/Contact">Contact</Link>
      </div>
    </div>
  );
}

export default Menuitem;

import React from "react";
import ImgSlide from './ImgSlide';
import Celebrationsec from './Celebrationsec';
import Youtubesec from './Youtubesec';
import Welcomepage from './Welcomepage';
import Report from './Report';
import VideoSlider from "./VideoSlider";

function Home (){
    return (
            <div>
                 <div className="Homesec">
        <Celebrationsec/>
        <ImgSlide/>
        <Youtubesec/>
        </div>
        <Welcomepage/>
        <Report/>
            </div>
    )
}
export default Home;
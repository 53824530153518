import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Navhead from './Navhead';
import Footer from './Footer';
import Home from "./Home";
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Imggallery from './Imggallery';
import Itemmap from './Itemmap';
import Contact from './Contact';
import HamburgerMenu from './HamburgerMenu';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PageNotFound from './PageNotFound';
import NotAvailable from './NotAvailable';
import FileComplain from './FileComplain';
import Tender from './Tender';
import E_magazine from './E_magazine';
import E_Audit from './E_Audit';
import E_Balance_Sheet from './E_Balance_Sheet';
import Budget from './Budget';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain:  process.env.REACT_APP_AUTH_DOMAIN,
   databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId : process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId:process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {

 
  return (
    <BrowserRouter>
    
        <Header />
        <HamburgerMenu />
        <Navhead />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Imggallery" element={<Imggallery />} />
          <Route path="Itemmap" element={<Itemmap/>}/>
          <Route path="Contact" element={<Contact/>}/>
          <Route path="birth_registration" element={<NotAvailable/>}/>
          <Route path="death_registration" element={<NotAvailable/>}/>
          <Route path="property" element={<NotAvailable/>}/>
          <Route path="water_Bill" element={<NotAvailable/>}/>
          <Route path="compaint" element={<FileComplain/>}/>
          <Route path="dept" element={<NotAvailable/>}/>
          <Route path="/tender" element={<Tender/>}/>
          <Route path="/E_magazine" element={<E_magazine/>}/>
          <Route path="/E_Audit" element={<E_Audit/>}/>
          <Route path="/E_Balance_Sheet" element={<E_Balance_Sheet/>}/>
          <Route path="/Budget" element={<Budget/>}/>
          
          
          
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
        
        <Footer />
      
    </BrowserRouter>
  );
}

export default App;

import React from "react";
function Itemmap(){
return (
    <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d868.6503618897348!2d78.42812804505226!3d29.440442854952174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390bc4e1dde55555%3A0xd6b3f5032d8c932f!2sNagar%20Palika!5e0!3m2!1sen!2sin!4v1693635161539!5m2!1sen!2sin" width="100%" height="600" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


    </div>
)
}
export default Itemmap;
import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from 'firebase/database';
import download from"./images/download.gif"

function Budget(){
  const db = getDatabase();

  const [data, setData] = useState(null);
  const [datakeys, setDatakeys] = useState(null);

  useEffect(() => {
    const dataRef = ref(db, '/PDF/Budget'); // Replace with your database path

    // Listen for changes to the data
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if(fetchedData){
        setDatakeys(Object.keys(fetchedData));}
      setData(fetchedData);
    });
  }, [db]);
  console.log(data);
  console.log(datakeys);

        

return(
  <div className="centered-box">
  <h2 style={{textAlign:"center"}}>Budget</h2>
 
  {
  datakeys!=null ? (
    datakeys.map((key, index) => (
       
      <div className="insidecentered" key={index}>
        <h1>{key} </h1>
        <a href={data[key]} target="_blank">  
          <img src={download} alt="" />
        </a>
      </div>
      
    ))
  ) : (
    <p className="No_data">No data to show</p>
  )
}




</div>
)
}

export default Budget;
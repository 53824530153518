// src/components/HamburgerMenu.js
import React, { useState } from 'react';
import logo from "./images/uplogo.png";
import { Link } from 'react-router-dom';
import Menuitem from './menuitem';


function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
  <div>
    <div className="hamburger-menu">
    <div className="logo">
      <Link to="/">
        <img id="logo" src={logo} alt="Home Logo" />
      </Link>
    </div>
    <div className="hamburger-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      </div>
      <div className={`menu-item ${isOpen ? 'open' : ''}`}>
        <Menuitem/>
    </div>
 
  </div>
  )
}

export default HamburgerMenu;

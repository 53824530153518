// src/components/Contact.js
import React, { useEffect, useState } from 'react';





function Contact() {

 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {name,email,message}=formData;
    const res=fetch(
      "https://nppn-5fe5f-default-rtdb.firebaseio.com/Messages.json",
      {
        method:"POST",
        headers:{
          "Content-Type":"application/json",
        },
        body:JSON.stringify({
          name,
          email,
          message
        }),
      }
    );

    if(res){
      alert("Message Sent");
    }else{
      alert("Something went Wrong Please Try agin Later");
    }

    
    
    // Add code here to handle form submission (e.g., sending data to a server)
    
  };

  return (
    <div>
    <div className="contact-page">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit">Send</button>
      </form>
    </div>

    
    </div>
  );
}

export default Contact;

import React, { useState } from 'react';
import { getDatabase, ref, push,get,child, set } from 'firebase/database';
import { getStorage,getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';



function FileComplain() {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    address: '',
    description: '',
    images: [],
  });

  async function uploadDataAndImages(formData) {
    const db = getDatabase();
    const storage = getStorage();
  
    // Upload data to the Realtime Database
    const dataRef = ref(db, 'complaints');
    const newComplaintRef = push(dataRef);
    
    // Check if the complaint data already exists
    const dataExists = await get(child(dataRef, 'complaintData'));
    if (!dataExists.exists()) {
      await set(newComplaintRef, formData);
    }
  
    // Upload images to Firebase Storage
    const imageRefs = [];
    for (const image of formData.images) {
      const imageName = `${Date.now()}_${image.name}`;
      const storageImageRef = storageRef(storage, `complaint_images/${imageName}`);
      await uploadBytes(storageImageRef, image);
      const imageUrl = await getDownloadURL(storageImageRef);
  
      imageRefs.push(imageUrl);
    }
  
    // Update the complaint data with image URLs
    await set(child(newComplaintRef, 'imageUrls'), imageRefs);
  
    return newComplaintRef.key;
  }
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = async (e) => {
    const imagesArray = Array.from(e.target.files);
    setFormData({ ...formData, images: imagesArray });
  
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Store form data in Firebase Realtime Database
  
    try {
        const imageRefs = await uploadDataAndImages(formData);
    
        // Optionally, do something with imageRefs (e.g., save them in the database)
    
        console.log('Form Data and Images Uploaded Successfully', imageRefs);
        setFormData({
            name: '',
            mobileNumber: '',
            address: '',
            description: '',
            images: [],
          });
      } catch (error) {
        console.error('Error Uploading Form Data and Images', error);
      }
  };

  return (
    <div className="complaint-form-container">
        
    <h2>शिकायत फॉर्म</h2>
    <div style={{display:"flex",padding:"2vw"}}>
    <form onSubmit={handleSubmit} className="complaint-form">
      <div className="form-group">
        <label>शिकायतकर्ता का नाम:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>मोबाइल नंबर:</label>
        <input
          type="text"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>पता:</label>
        <textarea
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          required
        ></textarea>
      </div>
      <div className="form-group">
        <label>शिकायत का विवरण:</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          required
        ></textarea>
      </div>
      <div className="form-group">
        <label>चित्र अपलोड करें:</label>
        <input
          type="file"
          name="images"
          onChange={handleImageChange}
          accept="image/*"
          multiple
        />
      </div>
     
      <button type="submit">Submit</button>
    </form>
    
    <div className="image-preview">
          {formData.images.map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              alt={`Image ${index}`}
            />
          ))}
        </div>
  </div>
  </div>
  );
}

export default FileComplain;

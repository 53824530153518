import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from 'firebase/database';
function Welcomepage(){
    const db = getDatabase();

  const [data, setData] = useState(null);
  const[imagedata,setImagedata]=useState();
  const[imagename,setname]=useState();

  useEffect(() => {
    const dataRef = ref(db, '/welcomesec'); // Replace with your database path

    // Listen for changes to the data
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(Object.values(fetchedData || {}));
    });

    const imgdataRef = ref(db, '/Officer/OfficerImage'); // Replace with your database path
    const imgnameRef = ref(db, '/Officer/Officername'); // Replace with your database path

    // Listen for changes to the data
    onValue(imgdataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      setImagedata(Object.values(fetchedData || {}));
    });

    onValue(imgnameRef, (snapshot) => {
      const fetchedData = snapshot.val();
      setname(Object.values(fetchedData || {}));
    });
  }, [db]);



return(
    <div id="container1" class="container">
        
        <div class="box first">
            <h2>Senior Clerk</h2>
            {imagedata?(
            <img src={imagedata[1]} alt=""/>
            )
              :(
                <p>Loading</p>
              )
            }
            {imagename?  (
            <p 	>{imagename[1]}</p>
            )
            :(
              <p>Name</p>
            )
            }
        </div>

       
        <div class="middle">
           {data ? (
            <>
           <h1>{data[1]}</h1>
            <p>{data[0]}</p>  
            </>          
            )
            : (
            <p>Loading....</p>)
           }
        </div>

       
        <div class="box last">
            <h2>Executive Officer</h2>
            {imagedata?(
            <img src={imagedata[0]} alt=""/>
            )
              :(
                <p>Loading</p>
              )
            }
           {imagename?  (
            <p 	>{imagename[0]}</p>
            )
            :(
              <p>Name</p>
            )
            }
        </div>
</div>

)
}
export default Welcomepage;
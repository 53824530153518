import React from "react";

function Report(){
return(
    <div class="flex-container">
        <div class="flex-item">
            <h2>Report</h2>
            <p><a href="#"> E-audit</a></p>
	    <p><a href="#"> E-Balance sheet</a></p>
	   <p><a href="#"> Audit Certificate</a></p>
	    <p><a href="#"> Budget</a></p>
        </div>

        
        <div class="flex-item">
            <h2>Online Services</h2>
             <ul style={{listStyleType:"none",textAlign:"left",lineHeight:"2"}}>
                <li><a href="#">E News letter(16/11/2018)</a></li>
                <li><a href="#">Birth Verification</a></li>
                <li><a href="#">Death Verification</a></li>
                <li><a href="#">Property Tax</a></li>
                <li><a href="#">Water Bill</a></li>
                <li><a href="#">Lodge a Complaint</a></li>
                <li><a href="#">Details of Employees</a></li>
                <li><a href="#">Ward Details</a></li>
                <li><a href="#">Tender Result</a></li>
          </ul>
        </div>

      
        <div class="flex-item">
            <h2>Others Links</h2>
              <p><a href="#">RTI Act 2005</a></p>
    <p><a href="#">Map</a></p>
    <p><a href="#">Civil Charter</a></p>
    <p><a href="#">Gallery</a></p>
    <p><a href="#">Important Work</a></p>
    <p><a href="#">File RTI</a></p>
    <p><a href="#">Plans</a></p>
    <p><a href="#">E-Procurement</a></p>
        </div>
    </div>
)
}

export default Report;
import React, { useEffect, useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getDatabase, ref, onValue } from 'firebase/database';

function ImgSlide() {
  const db = getDatabase();

  const [data, setData] = useState(null);

  useEffect(() => {
    const dataRef = ref(db, '/imgslider'); // Replace with your database path

    // Listen for changes to the data
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(Object.values(fetchedData || {}));
    });
  }, [db]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
      autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div className="centered-slider">
      <Slider {...sliderSettings}>
        {data ? (
          data.map((image, index) => (
            <div key={index}>
              <img className="imgset" id="sideimg" src={image} alt={`Image ${index + 1}`} />
            </div>
          ))
        ) : (
          <p>Loading....</p>
        )}
      </Slider>
    </div>
  );
}

export default ImgSlide;

import React from "react";

function Footer(){

return(
    <footer>
        &copy; 2023 Nagar Pallika Parishad Nagina.
    </footer>
)

}

export default Footer;
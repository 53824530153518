import React, { useEffect, useState } from "react";

import { getDatabase, ref, onValue } from 'firebase/database';



function Youtubesec() {


  const db = getDatabase();

  const [data, setData] = useState(null);

  useEffect(() => {
    const dataRef = ref(db, '/Youtube'); // Replace with your database path

    // Listen for changes to the data
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(Object.values(fetchedData || {}));
    });
  }, [db]);



  if (!data) {
    return (
      <div className="no-videos-message">
        <p>No videos to display.</p>
      </div>
    );
  }

  return (
    <div className="video-gallery yousec">
      {data.map((video) => (
        <div  className="video">
          
         <iframe src={`https://www.youtube.com/embed/${video}`} title="Video" allowFullScreen />
          
        </div>
      ))}
    </div>
  );
}

export default Youtubesec;
